var utils = require("../../utils/utils");
var helpers = require("../../utils/helpers");


function IsWorkTimeArgument(date, unit, task, id, calendar){
	this.date = date;
	this.unit = unit;
	this.task = task;
	this.id = id;
	this.calendar = calendar;
	return this;
}

function ClosestWorkTimeArgument(date, dir, unit, task, id, calendar){
	this.date = date;
	this.dir = dir;
	this.unit = unit;
	this.task = task;
	this.id = id;
	this.calendar = calendar;
	return this;
}

function CalculateEndDateArgument(start_date, duration, unit, step, task, id, calendar){
	this.start_date = start_date;
	this.duration = duration;
	this.unit = unit;
	this.step = step;
	this.task = task;
	this.id = id;
	this.calendar = calendar;
	return this;
}

function GetDurationArgument(start, end, task, calendar) {
	this.start_date = start;
	this.end_date = end;
	this.task = task;
	this.calendar = calendar;
	this.unit = null;
	this.step = null;
	return this;
}

var calendarArgumentsHelper = function(gantt){
	return {
		getWorkHoursArguments: function () {
			var config = arguments[0];
			if (helpers.isDate(config)) {
				config = {
					date: config
				};
			} else {
				config = utils.mixin({}, config);
			}
			return config;
		},
		setWorkTimeArguments: function () {
			return arguments[0];
		},
		unsetWorkTimeArguments: function () {
			return arguments[0];
		},
		isWorkTimeArguments: function () {
			var config = arguments[0];
			if(config instanceof IsWorkTimeArgument){
				return config;
			}

			var processedConfig;
			if (!config.date) {
				//IsWorkTimeArgument(date, unit, task, id, calendar)
				processedConfig = new IsWorkTimeArgument(arguments[0], arguments[1], arguments[2], null, arguments[3]);
			} else {
				processedConfig = new IsWorkTimeArgument(config.date, config.unit, config.task, null, config.calendar);
			}

			processedConfig.unit = processedConfig.unit || gantt.config.duration_unit;

			return processedConfig;
		},
		getClosestWorkTimeArguments: function (arg) {
			var config = arguments[0];
			if (config instanceof ClosestWorkTimeArgument)
				return config;

			var processedConfig;
			if (helpers.isDate(config)) {
				processedConfig = new ClosestWorkTimeArgument(config);

			} else {
				processedConfig = new ClosestWorkTimeArgument(
					config.date,
					config.dir,
					config.unit,
					config.task,
					null,//config.id,
					config.calendar
				);
			}

			if(config.id){
				processedConfig.task = config;
			}
			processedConfig.dir = config.dir || 'any';
			processedConfig.unit = config.unit || gantt.config.duration_unit;

			return processedConfig;
		},

		_getStartEndConfig: function (param) {
			var argumentType = GetDurationArgument;
			var config;
			if (param instanceof argumentType)
				return param;

			if (helpers.isDate(param)) {
				config = new argumentType(arguments[0], arguments[1], arguments[2], arguments[3]);
			} else {
				config = new argumentType(param.start_date, param.end_date, param.task);
				if (param.id) {
					config.task = param;
				}
			}

			config.unit = config.unit || gantt.config.duration_unit;
			config.step = config.step || gantt.config.duration_step;
			config.start_date = config.start_date || config.start || config.date;

			return config;
		},

		getDurationArguments: function (start, end, unit, step) {
			return this._getStartEndConfig.apply(this, arguments);
		},

		hasDurationArguments: function (start, end, unit, step) {
			return this._getStartEndConfig.apply(this, arguments);
		},

		calculateEndDateArguments: function (start, duration, unit, step) {
			var config = arguments[0];
			if (config instanceof CalculateEndDateArgument)
				return config;

			var processedConfig;
			//CalculateEndDateArgument(start_date, duration, unit, step, task, id, calendar)
			if (helpers.isDate(config)) {
				processedConfig = new CalculateEndDateArgument(
					arguments[0],
					arguments[1],
					arguments[2],
					undefined,
					arguments[3],
					undefined,
					arguments[4]
				);

			} else {
				processedConfig = new CalculateEndDateArgument(
					config.start_date,
					config.duration,
					config.unit,
					config.step,
					config.task,
					null,//config.id,
					config.calendar
				);
			}
			if(config.id){
				processedConfig.task = config;
			}

			processedConfig.unit = processedConfig.unit || gantt.config.duration_unit;
			processedConfig.step = processedConfig.step || gantt.config.duration_step;

			return processedConfig;
		}
	};
};


module.exports = calendarArgumentsHelper;