var getBarRectangle = require("./viewport/get_bar_rectangle");
var createBaseBarRender = require("./task_bar_render");

function createTaskRenderer(gantt){
	var defaultRender = createBaseBarRender(gantt);

	function renderSplitTask(task, timeline) {
		if (gantt.isSplitTask(task) && ((gantt.config.open_split_tasks && !task.$open) || !gantt.config.open_split_tasks)) {
			var el = document.createElement('div'),
				sizes = gantt.getTaskPosition(task);

			var sub_tasks = gantt.getChildren(task.id);


			for (var i = 0; i < sub_tasks.length; i++) {
				var child = gantt.getTask(sub_tasks[i]);

				var element = defaultRender(child, timeline);
				if(!element)
					continue;

				var padding = Math.floor((gantt.config.row_height - sizes.height) / 2);

				element.style.top = (sizes.top + padding) + "px";
				element.className += " gantt_split_child";

				el.appendChild(element);
			}
			return el;
		}
		return false;
	}
	return {
		render: renderSplitTask,
		update: null,
		getRectangle: getBarRectangle
	};
}

module.exports = createTaskRenderer;