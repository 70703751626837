module.exports = function(gantt) {
	var TreeDataStore = require("./datastore/treedatastore");

	var old_has_children = TreeDataStore.prototype.hasChild;
	gantt.$data.tasksStore.hasChild = function (id) {
		if (old_has_children.apply(this, arguments))
			return true;
		if (this.exists(id)) {
			return this.getItem(id)[gantt.config.branch_loading_property];
		}
		return false;
	};

	function needLoading(id) {
		if (gantt.config.branch_loading && gantt._load_url) {
			var was_rendered = gantt.getUserData(id, "was_rendered");
			// call ajax only if branch has children
			if (!was_rendered && (!gantt.getChildren(id).length && gantt.hasChild(id))) {
				return true;
			}
		}
		return false;
	}

	gantt.attachEvent("onTaskOpened", function (id) {
		if (gantt.config.branch_loading && gantt._load_url) {
			// call ajax only if branch has children
			if (needLoading(id)) {
				var url = gantt._load_url;
				url = url.replace(/(\?|&)?parent_id=.+&?/, "");
				var param = url.indexOf("?") >= 0 ? "&" : "?";
				var y = gantt.getScrollState().y || 0;

				gantt.load(url + param + "parent_id=" + encodeURIComponent(id), this._load_type, function () {
					if (y) {
						gantt.scrollTo(null, y);
					}
				});

				gantt.setUserData(id, "was_rendered", true);
			}
		}
	});

};