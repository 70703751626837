var getBarRectangle = require("./viewport/get_bar_rectangle");
var createBaseBarRender = require("./task_bar_render");

module.exports = function createTaskRenderer(gantt){
	var defaultRender = createBaseBarRender(gantt);
	return {
		render: defaultRender,
		update: null,
		getRectangle: getBarRectangle
	};
};