var helpers = require("../../utils/helpers");

function clearTaskStoreHandler(self) {
	if (self._delayRender) {
		self._delayRender.$cancelTimeout();
	}

	if (!self.$gantt) {
		return;
	}

	var tasks = self.$gantt.$data.tasksStore;
	var ownStore = self.$config.rowStore;

	var handlerIdProperty = "_attached_" + ownStore.$config.name;
	if (self[handlerIdProperty]) {
		tasks.detachEvent(self[handlerIdProperty]);
		self[handlerIdProperty] = null;
	}

	if (ownStore.$attachedResourceViewHandler) {
		ownStore.detachEvent(ownStore.$attachedResourceViewHandler);
		ownStore.$attachedResourceViewHandler = null;
	}
}

function createMixin(_super){

	var initGrid = _super.prototype.init,
		destroyGrid = _super.prototype.destructor;

	return {
		init: function() {
			initGrid.apply(this, arguments);
			this._linkToTaskStore();
		},

		destructor: function() {
			clearTaskStoreHandler(this);
			destroyGrid.apply(this, arguments);
		},

		_linkToTaskStore: function () {
			if (this.$config.rowStore && this.$gantt.$data.tasksStore) {
				var tasks = this.$gantt.$data.tasksStore;
				var ownStore = this.$config.rowStore;
				clearTaskStoreHandler(this);

				var self = this;
				var delayRender = helpers.delay(function() {
					if (self.$gantt.getState().lightbox) {
						delayRender();
					} else {
						// because rowstore could be changed during timeout
						self.$config.rowStore.refresh();
					}
				}, 300);
				this._delayRender = delayRender;
				var handlerIdProperty = "_attached_" + ownStore.$config.name;

				if (!self[handlerIdProperty]) {
					self[handlerIdProperty] = tasks.attachEvent("onStoreUpdated", delayRender);
				}

				this.$gantt.attachEvent("onDestroy", function() {
					// detach events to don't call delayed tasks
					clearTaskStoreHandler(self);
					return true;
				});

				if (!ownStore.$attachedResourceViewHandler) {
					ownStore.$attachedResourceViewHandler = ownStore.attachEvent("onBeforeStoreUpdate", function() {
						if (self.$gantt.getState().lightbox) {
							return false;
						}

						if (delayRender.$pending) {
							delayRender.$cancelTimeout();
						}
						self._updateNestedTasks();
					});
				}
			}
		},

		_updateNestedTasks: function(){
			var gantt = this.$gantt;
			var resourceStore = gantt.getDatastore(gantt.config.resource_store);
			if (!resourceStore.$config.fetchTasks) {
				return;
			}

			var resourceProperty = gantt.config.resource_property;

			resourceStore.silent(function(){
				var toAddArray = [];
				var toAdd = {};
				var toDelete = {};

				resourceStore.eachItem(function(resource){
					if (resource.$role == "task") {
						toDelete[resource.id] = true;
						return;
					}

					var tasks = gantt.getTaskBy(resourceProperty, resource.id);
					tasks.forEach(function(task) {
						var copy = gantt.copy(task);
						copy.id = task.id + '_' + resource.id;

						copy.$task_id = task.id;
						copy.$resource_id = resource.id;
						copy[resourceStore.$parentProperty] = resource.id;
						copy.$role = "task";
						toAddArray.push(copy);
						toAdd[copy.id] = true;
					});
				});
				for (var id in toDelete) {
					if (!toAdd[id]) {
						resourceStore.removeItem(id);
					}
				}
				resourceStore.parse(toAddArray);
			});
		}
	};
}

module.exports = createMixin;