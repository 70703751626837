/*
 %d - the day as a number with a leading zero ( 01 to 31 );
 %j - the day as a number without a leading zero ( 1 to 31 );
 %D - the day as an abbreviation ( Sun to Sat );
 %l - the day as a full name ( Sunday to Saturday );
 %W - the ISO-8601 week number of the year. Weeks start on Monday; 1)
 %m - the month as a number without a leading zero ( 1 to 12 );
 %n - the month as a number with a leading zero ( 01 to 12);
 %M - the month as an abbreviation ( Jan to Dec );
 %F - the month as a full name ( January to December );
 %y - the year as a two-digit number ( 00 to 99 );
 %Y - the year as a four-digit number ( 1900–9999 );
 %h - the hour based on the 12-hour clock ( 00 to 11 );
 %H - the hour based on the 24-hour clock ( 00 to 23 );
 %i - the minute as a number with a leading zero ( 00 to 59 );
 %s - the second as a number without a leading zero ( 00 to 59 ); 2)
 %a - displays am (for times from midnight until noon) and pm (for times from noon until midnight);
 %A - displays AM (for times from midnight until noon) and PM (for times from noon until midnight).
*/

module.exports = function(gantt) {
	var dateHelper = {
		init: function () {
			var locale = gantt.locale;

			var s = locale.date.month_short;
			var t = locale.date.month_short_hash = {};
			for (var i = 0; i < s.length; i++)
				t[s[i]] = i;

			var s = locale.date.month_full;
			var t = locale.date.month_full_hash = {};
			for (var i = 0; i < s.length; i++)
				t[s[i]] = i;
		},
		date_part: function (date) {
			var old = new Date(date);
			date.setHours(0);
			this.hour_start(date);
			if (date.getHours() && //shift to yesterday on dst
				(date.getDate() < old.getDate() || date.getMonth() < old.getMonth() || date.getFullYear() < old.getFullYear()))
				date.setTime(date.getTime() + 60 * 60 * 1000 * (24 - date.getHours()));
			return date;
		},
		time_part: function (date) {
			return (date.valueOf() / 1000 - date.getTimezoneOffset() * 60) % 86400;
		},
		week_start: function (date) {
			var shift = date.getDay();
			if (gantt.config.start_on_monday) {
				if (shift === 0) shift = 6;
				else shift--;
			}
			return this.date_part(this.add(date, -1 * shift, "day"));
		},
		month_start: function (date) {
			date.setDate(1);
			return this.date_part(date);
		},
		quarter_start: function (date) {
			this.month_start(date);
			var m = date.getMonth(),
				res_month;

			if (m >= 9) {
				res_month = 9;
			} else if (m >= 6) {
				res_month = 6;
			} else if (m >= 3) {
				res_month = 3;
			} else {
				res_month = 0;
			}

			date.setMonth(res_month);
			return date;
		},
		year_start: function (date) {
			date.setMonth(0);
			return this.month_start(date);
		},
		day_start: function (date) {
			return this.date_part(date);
		},
		hour_start: function (date) {
			if (date.getMinutes())
				date.setMinutes(0);
			this.minute_start(date);

			return date;
		},
		minute_start: function (date) {
			if (date.getSeconds())
				date.setSeconds(0);
			if (date.getMilliseconds())
				date.setMilliseconds(0);
			return date;
		},
		_add_days: function (date, inc) {
			var ndate = new Date(date.valueOf());

			ndate.setDate(ndate.getDate() + inc);
			if (inc >= 0 && (!date.getHours() && ndate.getHours()) &&//shift to yesterday on dst
				(ndate.getDate() <= date.getDate() || ndate.getMonth() < date.getMonth() || ndate.getFullYear() < date.getFullYear()))
				ndate.setTime(ndate.getTime() + 60 * 60 * 1000 * (24 - ndate.getHours()));
			return ndate;
		},

		add: function (date, inc, mode) {
			/*jsl:ignore*/
			var ndate = new Date(date.valueOf());
			switch (mode) {
				case "day":
					ndate = this._add_days(ndate, inc);
					break;
				case "week":
					ndate = this._add_days(ndate, inc * 7);
					break;
				case "month":
					ndate.setMonth(ndate.getMonth() + inc);
					break;
				case "year":
					ndate.setYear(ndate.getFullYear() + inc);
					break;
				case "hour":
					/*
						adding hours/minutes via setHour(getHour() + inc) gives weird result when
						adding one hour to the time before switch to a Daylight Saving time

						example: //Sun Mar 30 2014 01:00:00 GMT+0100 (W. Europe Standard Time)
						new Date(2014, 02, 30, 1).setHours(2)
						>>Sun Mar 30 2014 01:00:00 GMT+0100 (W. Europe Standard Time)

						setTime seems working as expected
					 */
					ndate.setTime(ndate.getTime() + inc * 60 * 60 * 1000);
					break;
				case "minute":

					ndate.setTime(ndate.getTime() + inc * 60 * 1000);

					break;
				default:
					return this["add_" + mode](date, inc, mode);
			}
			return ndate;
			/*jsl:end*/
		},
		add_quarter: function (date, inc) {
			return this.add(date, inc * 3, "month");
		},

		to_fixed: function (num) {
			if (num < 10) return "0" + num;
			return num;
		},
		copy: function (date) {
			return new Date(date.valueOf());
		},
		date_to_str: function (format, utc) {
			format = format.replace(/%[a-zA-Z]/g, function (a) {
				switch (a) {
					case "%d":
						return "\"+to_fixed(date.getDate())+\"";
					case "%m":
						return "\"+to_fixed((date.getMonth()+1))+\"";
					case "%j":
						return "\"+date.getDate()+\"";
					case "%n":
						return "\"+(date.getMonth()+1)+\"";
					case "%y":
						return "\"+to_fixed(date.getFullYear()%100)+\"";
					case "%Y":
						return "\"+date.getFullYear()+\"";
					case "%D":
						return "\"+locale.date.day_short[date.getDay()]+\"";
					case "%l":
						return "\"+locale.date.day_full[date.getDay()]+\"";
					case "%M":
						return "\"+locale.date.month_short[date.getMonth()]+\"";
					case "%F":
						return "\"+locale.date.month_full[date.getMonth()]+\"";
					case "%h":
						return "\"+to_fixed((date.getHours()+11)%12+1)+\"";
					case "%g":
						return "\"+((date.getHours()+11)%12+1)+\"";
					case "%G":
						return "\"+date.getHours()+\"";
					case "%H":
						return "\"+to_fixed(date.getHours())+\"";
					case "%i":
						return "\"+to_fixed(date.getMinutes())+\"";
					case "%a":
						return "\"+(date.getHours()>11?\"pm\":\"am\")+\"";
					case "%A":
						return "\"+(date.getHours()>11?\"PM\":\"AM\")+\"";
					case "%s":
						return "\"+to_fixed(date.getSeconds())+\"";
					case "%W":
						return "\"+to_fixed(getISOWeek(date))+\"";
					case "%w":
						return "\"+to_fixed(getWeek(date))+\"";
					default:
						return a;
				}
			});
			if (utc) format = format.replace(/date\.get/g, "date.getUTC");
			var dateToStr = new Function("date", "to_fixed", "locale", "getISOWeek", "getWeek", "return \"" + format + "\";");

			return function (date) {
				return dateToStr(date, dateHelper.to_fixed, gantt.locale, dateHelper.getISOWeek, dateHelper.getWeek);
			};
		},
		str_to_date: function (format, utc) {
			var splt = "var temp=date.match(/[a-zA-Z]+|[0-9]+/g);";
			var mask = format.match(/%[a-zA-Z]/g);
			for (var i = 0; i < mask.length; i++) {
				switch (mask[i]) {
					case "%j":
					case "%d":
						splt += "set[2]=temp[" + i + "]||1;";
						break;
					case "%n":
					case "%m":
						splt += "set[1]=(temp[" + i + "]||1)-1;";
						break;
					case "%y":
						splt += "set[0]=temp[" + i + "]*1+(temp[" + i + "]>50?1900:2000);";
						break;
					case "%g":
					case "%G":
					case "%h":
					case "%H":
						splt += "set[3]=temp[" + i + "]||0;";
						break;
					case "%i":
						splt += "set[4]=temp[" + i + "]||0;";
						break;
					case "%Y":
						splt += "set[0]=temp[" + i + "]||0;";
						break;
					case "%a":
					case "%A":
						splt += "set[3]=set[3]%12+((temp[" + i + "]||'').toLowerCase()=='am'?0:12);";
						break;
					case "%s":
						splt += "set[5]=temp[" + i + "]||0;";
						break;
					case "%M":
						splt += "set[1]=locale.date.month_short_hash[temp[" + i + "]]||0;";
						break;
					case "%F":
						splt += "set[1]=locale.date.month_full_hash[temp[" + i + "]]||0;";
						break;
					default:
						break;
				}
			}
			var code = "set[0],set[1],set[2],set[3],set[4],set[5]";
			if (utc) code = " Date.UTC(" + code + ")";
			var strToDate = new Function("date", "locale", "var set=[0,0,1,0,0,0]; " + splt + " return new Date(" + code + ");");

			return function (dateString) {
				return strToDate(dateString, gantt.locale);
			};
		},
		getISOWeek: function (ndate) {
			return gantt.date._getWeekNumber(ndate, true);
		},
		_getWeekNumber: function(ndate, isoWeek){
			if (!ndate) return false;
			var nday = ndate.getDay();
			if(isoWeek){
				if (nday === 0) {
					nday = 7;
				}
			}
			var first_thursday = new Date(ndate.valueOf());
			first_thursday.setDate(ndate.getDate() + (4 - nday));
			var year_number = first_thursday.getFullYear(); // year of the first Thursday
			var ordinal_date = Math.round((first_thursday.getTime() - new Date(year_number, 0, 1).getTime()) / 86400000); //ordinal date of the first Thursday - 1 (so not really ordinal date)
			var week_number = 1 + Math.floor(ordinal_date / 7);
			return week_number;
		},

		getWeek: function(ndate){
			return gantt.date._getWeekNumber(ndate, gantt.config.start_on_monday);
		},
		getUTCISOWeek: function (ndate) {
			return gantt.date.getISOWeek(ndate);
		},
		convert_to_utc: function (date) {
			return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
		},
		parseDate: function (date, format) {
			// raw date may be of type string, number (timestamp) or something else
			// do not check for instanceof Date explicitly, since we may swap native date with different date implementation at some point
			if (date && !date.getFullYear) {
				if (typeof(format) !== "function") {
					if (typeof(format) === "string") {
						if (format === "parse_date") {
							format = gantt.templates.parse_date;
							if (gantt.defined(gantt.templates.xml_date) && gantt.templates.parse_date !== gantt.templates.xml_date) {
								format = gantt.templates.xml_date;
							}
						} else {
							format = gantt.defined(gantt.templates[format]) ? gantt.templates[format] : gantt.date.str_to_date(format);
						}
					} else {
						format = gantt.templates.xml_date !== gantt.templates.parse_date ? gantt.templates.xml_date : gantt.templates.parse_date;
					}
				}
				if (date) {
					date = format(date);
				} else {
					date = null;
				}
			}
			return date;
		}
	};
	return dateHelper;
};