module.exports = function(gantt){

	// no deprecated methods for now

	// eslint-disable-next-line no-unused-vars
	function deprecated(badCode, goodCode, versionDeprecated, versionDeleted) {

		var formatting = gantt.env.isIE ? "" : "%c";
		versionDeprecated = versionDeprecated || "v6.0";
		versionDeleted = versionDeleted || "v7.0";

		var message = [
			formatting, "\"", badCode, "\"",  formatting,
			" has been deprecated in dhtmlxGantt ", versionDeprecated, " and will stop working in ", versionDeleted,". Use ",
			formatting, "\"", goodCode, "\"",  formatting,
			" instead. \nSee more details at http://docs.dhtmlx.com/gantt/migrating.html "
		].join("");

		var log = window.console.warn || window.console.log;

		var args = [message];
		if(!gantt.env.isIE){
			args = args.concat(["font-weight:bold", "font-weight:normal", "font-weight:bold", "font-weight:normal"]);
		}

		log.apply(window.console, args);
	}

	// gantt.getSlack is defined inside an extension, leave it without message for now

};